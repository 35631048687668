<template>
  <div>
    <b-row>
      <b-col md="8">
        <div class="input-group">
          <input
              id="example-search-input"
              class="form-control py-2 border-right-0 border"
              type="search"
              v-model="filters.search"
              :placeholder="$t('Search')"
          >
          <span class="input-group-append">
                <b-button variant="primary" @click="fetchList">
                  <b-icon icon="search" />
                </b-button>
              </span>
        </div>
      </b-col>
      <b-col md="4">
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="handleAdd">{{$t('Add')}}</b-button>
        </div>
      </b-col>
    </b-row>
    <div class="w-100 mt-2">
      <b-card>
        <b-table
            striped
            hover
            :items="datas"
            :fields="fields"
            responsive=""
        >
          <template #cell(id)="data">
            <span class="text-md text-primary"> {{ data.index + 1 }}</span>
          </template>
          <template #cell(register_date)="{item}">
            <b-alert variant="info" show>
                  <span class="d-flex justify-content-center mt-1">
                  <span>
                    {{ item.register_date | dateFormat }}
                  </span>
                  <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
                </span>
            </b-alert>
          </template>
          <template #cell(action)="data">
            <b-button
                size="sm"
                variant="warning"
                @click="handleEdit(data.item.id)"
            >
              <b-icon icon="pencil" />
            </b-button>
            <b-button
                class="ml-1"
                size="sm"
                variant="danger"
                @click="handleDelete(data.item.id)"
            >
              <b-icon icon="trash" />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </div>
    <b-modal
        ref="modal"
        v-model="modalVisible"
        :title="modalTitle"
        hide-footer
        size="lg"
    >
      <DocCategoryForm :id="id" @saved="fetchList"/>
    </b-modal>
    <div class="overflow-auto text-center">
    </div>
  </div>
</template>

<script>

import { listTemplate } from '@/utils/mixins/listTemplate';
import { getCategories, deleteCategory } from '@/api/documents';
// eslint-disable-next-line import/extensions
import DocCategoryForm from '@/views/loginpages/moderator/components/categorydoc/DocCategoryForm'

const actions = {
  get: getCategories,
  delete: deleteCategory,
}

export default {
  name: 'SubCategory',
  components: {
    DocCategoryForm,
  },
  mixins: [
    listTemplate,
  ],
  data() {
    return {
      cats: [],
      actions,
      filters: {
        parent_id: this.$route.params.id,
      },
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'name',
          label: this.$t('Name'),
        },
        {
          key: 'register_date',
          label: this.$t('Register_Date'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
